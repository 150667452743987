import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../styles/layout/VacancyPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CopyToClipboard from 'react-copy-to-clipboard';
import VacancyPart from '../components/VacancyPart';
import { experienceOptions } from '../forms/constants/vacancyFormConstants';
import { getSalaryText } from '../utils';
import { getVacancy } from '../actions/vacancies';
import Breadcrumbs from '../components/Breadcrumbs';
import CreateResponse from './CreateResponse';
import preloader from '../assets/img/preloader.gif';
import ErrorMessage from '../components/ErrorMessage';
import PublicHeader from '../components/PublicHeader';
import Footer from '../components/Footer';
import { createResponse } from '../actions/responses';
import history from '../routes/history';
import appConfig from '../configs/appConfig';


const VacancyPage = (props) => {
  const {
    match: { params: { id: currentVacancyId } },
    getVacancy,
    activeVacancy,
    errors,
    authComplete,
  } = props;
  const [isCopying, setIsCopying] = useState(false);

  if (!authComplete) {
    history.push(`/login/?vacancy=${currentVacancyId}`);
  }

  useEffect(() => {
    getVacancy(currentVacancyId);
  }, []);

  let vacancyPageContent;

  if (!activeVacancy) {
    vacancyPageContent = (
      <div className="loader">
        <img className="loader__gif" src={preloader} alt="Загрузка..." />
      </div>
    );
  } else {
    const {
      title,
      salary_from,
      salary_to,
      experience,
      address,
      city,
      description,
      requirements,
      conditions,
      currency,
      questions,
      team_title,
      logo,
      id,
    } = activeVacancy;


    const breadcrumbs = [
      { title: 'Все вакансии', link: '/all-vacancies' },
      { title: `${title}`, link: '' },
    ];

    const currentExperience = experienceOptions.find(({ value }) => value === experience);
    const salaryText = getSalaryText(salary_from, salary_to, currency);

    vacancyPageContent = (
      <>
        <Row>
          <Col xs="12" className="vacancy-page-main-info">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
            <h1 className="vacancy-item-title mb-3 mt-4">
              {title}
              <CopyToClipboard text={`${appConfig.JOB_SITE_URL}/vacancies/${id}`}>
                <FontAwesomeIcon
                  className="ml-2 mb-0 copy-by-click-icon"
                  size="sm"
                  icon={isCopying ? 'check' : 'copy'}
                  color={isCopying ? 'rgb(2, 168, 141)' : ''}
                  title="Копировать ссылку на вакансию"
                  onClick={() => setIsCopying(true)}
                />
              </CopyToClipboard>
            </h1>
            <Row className="vacancy-page-required mb-4">
              {logo && (
              <div className="vacancy-page-picture-wrapper mb-4">
                <img
                  src={logo}
                  alt="Фото вакансии"
                />
              </div>
              )}
              <Col col="6" className="no-gutters">
                <Col col="3" className="vacancy-page-desc mb-4">{team_title}</Col>
                <Col col="3" className="mb-4">{salaryText}</Col>
                <Col xs="12">
                  <div className="vacancy-page-required">Требуемый опыт</div>
                  <div className="vacancy-page-desc mb-4">{currentExperience.label}</div>
                </Col>
                <Col xs="12">
                  <div className="vacancy-page-required ">Адрес</div>
                  <div className="vacancy-page-desc">
                    {city && <span>{`${city} `}</span>}
                    {address && <span>{`${address}`}</span>}
                  </div>
                </Col>
              </Col>

            </Row>
          </Col>
        </Row>
        {description && <VacancyPart title="Описание вакансии" description={description} />}
        {requirements && <VacancyPart title="Требования" description={requirements} />}
        {conditions && <VacancyPart title="Условия" description={conditions} />}

        <CreateResponse
          vacancy={activeVacancy}
          questions={questions}
        />
      </>
    );
  }

  if (errors.hasOwnProperty('getVacancyError')) {
    vacancyPageContent = <ErrorMessage errorName="getVacancyError" />;
  }

  return (
    <>
      {!authComplete && <PublicHeader />}
      <div className="vacancy-content container mt-4">
        {vacancyPageContent}
      </div>
      {!authComplete && <Footer />}
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    entities: { allVacancies: { activeVacancy = null } },
    app: { authComplete },
    questions,
    errors,
  } = state;
  return {
    authComplete,
    activeVacancy,
    questions,
    errors,
  };
};

VacancyPage.defaultProps = {
  activeVacancy: {},
};

VacancyPage.propTypes = {
  match: PropTypes.object.isRequired,
  getVacancy: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  activeVacancy: PropTypes.object,
  authComplete: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => ({
  createResponse: response => dispatch(createResponse(response)),
  getVacancy: id => dispatch(getVacancy(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VacancyPage);
