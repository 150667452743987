import {
  APPLY_COUPON_FAIL,
  APPLY_COUPON_REQUEST,
  APPLY_COUPON_SUCCESS,
  CHANGE_EMAIL_FAIL,
  CHANGE_EMAIL_REQUEST,
  CHANGE_EMAIL_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  EDIT_PROFILE_FAIL,
  EDIT_PROFILE_REQUEST,
  EDIT_PROFILE_SUCCESS,
  CREATE_RESPONSE_SUCCESS,
  CREATE_RESPONSE_REQUEST,
  CREATE_RESPONSE_FAIL,
  RESET_API_STATUS,
} from '../constants';
import { createApiReducer } from '../utils/reducerUtils';


const resetApiStatus = (state, action) => {
  const newState = { ...state };
  delete newState[action.name];
  return newState;
};

const initialState = {};

const apiReducer = createApiReducer(
  initialState,
  [
    {
      types: [CHANGE_EMAIL_REQUEST, CHANGE_EMAIL_SUCCESS, CHANGE_EMAIL_FAIL],
      name: 'changeEmailStatus',
    },
    {
      types: [CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAIL],
      name: 'changePasswordStatus',
    },
    {
      types: [EDIT_PROFILE_REQUEST, EDIT_PROFILE_SUCCESS, EDIT_PROFILE_FAIL],
      name: 'editProfileStatus',
    },
    {
      types: [CREATE_RESPONSE_REQUEST, CREATE_RESPONSE_SUCCESS, CREATE_RESPONSE_FAIL],
      name: 'createResponseStatus',
    },
    {
      types: [APPLY_COUPON_REQUEST, APPLY_COUPON_SUCCESS, APPLY_COUPON_FAIL],
      name: 'applyCouponStatus',
    },
  ],
  {
    [RESET_API_STATUS]: resetApiStatus,
  },
);

export default apiReducer;
