import axios from '../api';
import {
  GET_RESPONSES_FAIL,
  GET_RESPONSES_REQUEST,
  GET_RESPONSES_SUCCESS,
  CREATE_RESPONSE_FAIL,
  CREATE_RESPONSE_SUCCESS,
  CREATE_RESPONSE_REQUEST,
} from '../constants';
import history from '../routes/history';


export const loadResponses = () => ({
  types: [GET_RESPONSES_REQUEST, GET_RESPONSES_SUCCESS, GET_RESPONSES_FAIL],
  callApi: () => axios.get('/js/responses/'),
});

export const createResponse = (response, authComplete) => ({
  name: 'response',
  types: [CREATE_RESPONSE_REQUEST, CREATE_RESPONSE_SUCCESS, CREATE_RESPONSE_FAIL],
  callApi: () => axios.post(`${!authComplete ? 'widget/' : 'js/'}responses/`, response),
  afterSuccessCall: () => {
    history.push('/all-vacancies');
  },
});
